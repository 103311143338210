<template>
  <div
    id="user-account"
  >
    <h4>
      {{ t('title.Account Management') }}
      <br>
      <small>
        {{ t('title.Account Management Info') }}
      </small>
    </h4>
    <!-- Main Account & Add Family -->
    <b-row>
      <!-- Main Account -->
      <b-col
        cols="12"
        md="6"
        xl="3"
      >
        <b-card>
          <b-card-text>
            <b-row>
              <b-col cols="9">
                <h6 class="pt-2">
                  {{ t('patient.hn') }} <small>{{ patientData.hn }}</small>
                </h6>
                <h4 class="text-wrap">
                  {{ patientData.prefix }}{{ patientData.firstName }} {{ patientData.lastName }} ({{ t('account.me') }})
                </h4>
              </b-col>
              <b-col
                cols="3"
                class="text-right"
              >
                <b-img
                  :src="require('@/assets/images/logo/sp_logo_white.png')"
                  class=""
                  height="45"
                  alt="Toast image"
                /><br>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-link
                  v-if="patientData.idCard !== currentIdCard"
                  href="#"
                  class="card-link"
                  @click="setHNView(patientData.idCard)"
                >
                  <feather-icon
                    icon="RepeatIcon"
                    class="text-warning"
                  />
                </b-link>
              </b-col>
              <b-col class="text-right">
                <!-- <b-link
                  href="#"
                  class="card-link"
                  @click="showFamilyListModal"
                >
                  <feather-icon
                    icon="RepeatIcon"
                    class="mr-50 text-info"
                  />
                </b-link> -->
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>

      <!-- Add Family -->
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="6"
        xl="3"
      >
        <b-card
          v-for="(data, index) in familyLists"
          :key="index"
        >
          <b-card-text>
            <b-row>
              <b-col cols="9">
                <h6 class="pt-2">
                  {{ t('patient.hn') }} <small>{{ data.hn }}</small>
                </h6>
                <h4 class=" text-wrap">
                  {{ data.familyName }} ({{ data.familyDescription }})
                </h4>
              </b-col>
              <b-col
                cols="3"
                class="text-right"
              >
                <b-img
                  :src="require('@/assets/images/logo/sp_logo_white.png')"
                  class=""
                  height="45"
                  alt="Toast image"
                /><br>
              </b-col>
              <b-col class="text-left">
                <b-link
                  v-if="data.idCard !== currentIdCard"
                  href="#"
                  class="card-link"
                  @click="setHNView(data.idCard)"
                >
                  <feather-icon
                    icon="RepeatIcon"
                    class="mr-50 text-warning"
                  />
                </b-link>
              </b-col>
              <b-col class="text-right">
                <b-link
                  href="#"
                  class="card-link"
                  @click="editFamilyDescription(data)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50 text-info"
                  />
                </b-link>
                <b-link
                  href="#"
                  class="card-link"
                  @click="RemoveFamily(data.accountFamilyID, data.idCard)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50 text-danger"
                  />
                </b-link>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <!-- <b-card
          v-for="(data, index) in familyLists"
          :key="index"
          border-variant="success"
          align="center"
        >
          <b-card-text>
            <h4 class="text-success">
              <feather-icon
                v-if="data.idCard === currentIdCard"
                icon="CheckCircleIcon"
                class="mr-50 text-warning text-right"
              />
              {{ data.familyName }} [{{ data.familyDescription }}]
            </h4>
            <h5 class="text-success">
              {{ t('patient.hn') }} {{ data.hn }}
            </h5>
            <b-row>
              <b-col class="text-left">
                <b-link
                  v-if="data.idCard !== currentIdCard"
                  href="#"
                  class="card-link"
                  @click="setHNView(data.idCard)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50 text-warning"
                  />
                </b-link>
              </b-col>
              <b-col class="text-right">
                <b-link
                  href="#"
                  class="card-link"
                  @click="editFamilyDescription(data)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50 text-info"
                  />
                </b-link>
                <b-link
                  href="#"
                  class="card-link"
                  @click="RemoveFamily(data.accountFamilyID, data.idCard)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50 text-danger"
                  />
                </b-link>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card> -->
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="6"
        xl="3"
      >
        <b-card
          class="text-center"
          @click="$router.push({ name: 'account-update' })"
        >
          <feather-icon
            icon="UserPlusIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ t('account.add') }}</span>
        </b-card>
        <!-- <b-button
          block
          variant="outline-primary"
          @click="$router.push({ name: 'account-update' })"
        >
          <feather-icon
            icon="UserPlusIcon"
            class="mr-50"
          />
          <span class="align-middle">เพิ่มสมาชิก</span>
        </b-button> -->

      </b-col>
    </b-row>
    <b-modal
      ref="familyListModal"
      content-class="shadow"
      title="Main - Hospital Number"
      no-fade
      ok-title="Accept"
    >
      <b-card-text>
        <b-form-group>
          <b-form-radio-group
            v-model="mainHNSelected"
            :options="familyLists"
            value-field="accountFamilyID"
            text-field="familyName"
            class="mt-1"
            stacked
          />
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'

export default {
  components: { },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      patientMainData: [],
      patientData: [],
      patientInfo: [],
      accountID: 0,
      familyLists: [],
      updateID: '',
      currentIdCard: '',
      dataWait: false,
      mainHNSelected: '',
    }
  },
  created() {
    this.patientInfo = JSON.parse(localStorage.getItem('patientData'))
    const accountData = JSON.parse(localStorage.getItem('accountData'))
    this.accountID = accountData.accountID
    this.currentIdCard = localStorage.getItem('patientView')
    this.loadProfile()
    this.loadProfileMain()
  },
  methods: {
    showFamilyListModal() {
      this.$refs.familyListModal.show()
    },
    loadProfile(loadType = null) {
      let patientViewID = localStorage.getItem('patientView')
      // console.log('viewID', patientViewID)
      if (patientViewID === null || patientViewID === undefined || patientViewID === '') {
        patientViewID = this.patientInfo.idCard
      }
      this.$http({
        url: `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/${patientViewID}/null`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        if (data.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          // this.patientData = data[0]
          // Update patient view to patientData
          if (this.patientInfo.idCard !== patientViewID) {
            console.log(JSON.stringify(data[0]), data[0])
            localStorage.setItem('patientData', JSON.stringify(data[0]))
            // this.loadPatientImage(data[0].hn)
          }
          if (loadType === 'C') {
            this.$router.push({ name: 'member-home' })
            // setTimeout(() => {
            //   this.$router.go(this.$router.currentRoute)
            // }, 500)
          } else {
            this.loadFamily()
          }
        }
      }).catch(error => {
        console.log('loadProfile', error)
      })
    },
    loadProfileMain() {
      this.patientMainData = JSON.parse(localStorage.getItem('patientTempData'))
      this.$http({
        url: `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/${this.patientMainData.idCard}/null`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        if (data.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.patientData = data[0]
        }
      }).catch(error => {
        console.log('loadProfileMain', error)
      })
    },
    async loadPatientImage(hn) {
      await this.$http({
        url: `http://phrapi.sriphat.com/api/Patient/PatientImage/${hn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        // console.log('Load Image')
        localStorage.setItem('patientImage', data)
        // this.ptImage = `data:image/jpeg;base64, ${data}`
      }).catch(error => {
        console.log('loadPatientImage', error)
      })
    },
    loadFamily() {
      if (this.accountID !== 0) {
        this.dataWait = true
        this.familyLists = []
        this.$http({
          url: `http://phrapi.sriphat.com/api/UserAuthen/listFamily/${this.accountID}`,
          method: 'GET',
          data: { },
        }).then(({ data }) => {
          this.familyLists = data
          this.dataWait = false
        }).catch(error => {
          console.log('loadProfile', error)
        })
      }
    },
    editFamilyDescription(familyData) {
      // console.log(familyData)
      this.updateID = familyData.accountFamilyID
      // return
      this.$swal({
        title: `<h6>ความสัมพันธ์ (${familyData.familyName} )</h6>`,
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: `${this.$t('Button Save')}`,
        cancelButtonText: `${this.$t('Button Cancel')}`,
        showLoaderOnConfirm: true,
        preConfirm(description) {
          // console.log(familyData.accountFamilyID)
          const updateData = { id: familyData.accountFamilyID, text: description }
          return updateData
        },
      }).then(result => {
        // console.log(result.isConfirmed)
        if (result.isConfirmed) {
          // console.log(result, familyData.accountFamilyID)
          this.$http({
            url: 'http://phrapi.sriphat.com/api/UserAuthen/EditFamilyDescription',
            method: 'POST',
            data: {
              AccountFamilyID: result.value.id,
              FamilyDescription: result.value.text,
            },
          }).then(({ data }) => {
            // console.log(data)
            this.loadFamily()
          }).catch(error => {
            console.log('editFamilyDescription', error)
          })
        }
      })
    },
    RemoveFamily(familyAccountID, familyIdCard) {
      console.log(familyAccountID, familyIdCard)
      this.$swal({
        title: 'Are you sure?',
        text: `${this.$t('title.Confirm Remove Customer')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          console.log(familyAccountID, familyIdCard, this.currentIdCard)
          this.$http({
            url: `http://phrapi.sriphat.com/api/UserAuthen/RemoveFamily/${familyAccountID}`,
            method: 'GET',
            data: { },
          }).then(({ data }) => {
            if (data) {
              this.loadFamily()
            }
          }).catch(error => {
            console.log('RemoveFamily', error)
          })
          // this.loadFamily()
          if (this.currentIdCard === familyIdCard) {
            localStorage.setItem('patientView', this.patientMainData.idCard)
            this.currentIdCard = this.patientMainData.idCard
            this.loadProfile()
          }
        }
      })
    },
    swapHNMain(familyAccountID) {
      this.$swal({
        title: 'Are you sure?',
        text: `${this.$t('title.Confirm Swap HN')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http({
            url: `http://phrapi.sriphat.com/api/UserAuthen/SwapHNMain/${this.accountID}/${familyAccountID}`,
            method: 'GET',
            data: { },
          }).then(({ data }) => {
            if (data) {
              this.loadFamily()
            }
          }).catch(error => {
            console.log('swapHNMain', error)
          })
        }
      })
    },
    setHNView(viewIdCard) {
      this.$swal({
        title: `${this.$t('are u sure')}`,
        text: `${this.$t('title.Confirm View HN')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // console.log('Set View HN', viewIdCard)
          localStorage.setItem('patientView', viewIdCard)
          this.currentIdCard = viewIdCard
          // this.$router.go(0)
          this.loadProfile('C')
        }
      })
    },
  },
}
</script>
